import MuiButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Link from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import debounce from 'debounce-promise';
import { parse as convertFromCSV, unparse as convertToCSV } from 'papaparse';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { downloadCSV, useTranslate, useDataProvider } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { LocalCompany } from '../../@types/common';
import { Button, Snackbar, Paper } from '../../components';
import { addWhitelistAction } from '../../redux/actions';
import { EMAIL_REGEX, whitelistCleaner } from '../../utils';
import { athensGray, blueGem, shark } from '../../utils/colors';
import UpdateWhitelistDialog, { Referer } from './updateWhitelistDialog';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { createEvent } from '../../utils/analytics';

const useAddEmail = makeStyles({
  addEmail: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    color: shark,

    '&:hover': {
      backgroundColor: athensGray,
    },

    '& p': {
      margin: '16px 10px',
    },
  },
  validateNewEmail: {
    border: `1px solid ${blueGem}`,
    display: 'flex',
    padding: '8px',

    '& > :first-child': {
      flexGrow: 1,
      paddingLeft: '60px',
    },
    '& > :not(:first-child)': {
      padding: '0 25px',
    },
  },
});

const useTableHead = makeStyles({
  cells: {
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    cursor: 'pointer',
  },
  open: {
    display: 'block',
  },
  listSource: {
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '2px solid #3C0CBF',
    padding: '12px 8px',
    gap: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    borderRadius: '8px',
    right: '10px',
    zIndex: 2,
    width: '183px',
    height: '236px',
  },
  sourceCellsTitle: {
    display: 'flex',
    justifyContent: 'right',
  },
  sourceCells: {
    fontWeight: 'bold',
    position: 'relative',
  },
  checkboxActive: {
    '&:checked': {
      backgroundColor: '#3C0CBF',
    },
  },
  policeCheckbox: {
    fontFamily: 'Poppins',
    width: '175px',
    height: '26px',
    fontStyle: 'normal',
    lineHeight: '26px',
    display: 'flex',
    alignItems: 'center',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  print: {
    '&:hover $listSource': {
      display: 'flex',
    },
  },
});

const useToolbarStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',

    '& > :first-child': {
      flexGrow: 1,
    },
  },
  searchIcon: {
    marginRight: '5px',
  },
  searchLabel: {
    padding: '2px 10px',
    margin: 'auto 10px',
    display: 'inline-flex',
    maxWidth: '200px',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid black',
  },
  selectedTitle: {
    alignItems: 'center',
    display: 'flex',
    background: athensGray,
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',

    '& > :first-child': {
      flexGrow: 1,
      fontWeight: 'bold',
    },
  },
  uploadButton: {
    letterSpacing: '0.75px',
    borderRadius: '20px',
    whiteSpace: 'nowrap',
  },
});

const useStyles = makeStyles({
  downloadButton: {
    borderRadius: '20px',
    margin: '15px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  tableFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

interface EnhancedAddEmailProps {
  setEmailExist: any;
}

const EnhancedAddEmail = (props: EnhancedAddEmailProps) => {
  const { setEmailExist } = props;
  const classes = useAddEmail();
  const dispatch = useDispatch();
  const t = useTranslate();
  const [displayAddEmail, setDisplayAddEmail] = useState(true);
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const handleAddEmail = (email: string) => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
      credentials: 'include',

      body: JSON.stringify({
        variables: { id: currentCompany.id, email },
        query: `
            mutation AddEmailsInAuthorizedUsers {
              AddEmailsInAuthorizedUsers
            (input: {
                companyID: "${currentCompany.id}",
                emails: "${[email]}"
              }) {
                newUsers {
                  id
                  email
                  companyID
                }
                toUnBlock {
                  id
                  email
                  companyID
                }
              }
            }
            `,
      }),
    })
      .then(result => result.json())
      .then(e => {
        if (e.data && e.data.AddEmailsInAuthorizedUsers) {
          const newUsers = e.data.AddEmailsInAuthorizedUsers.newUsers;
          if (newUsers && !newUsers.length) {
            setEmailExist(true);
          }
        }
        if (e.data.AddEmailsInAuthorizedUsers.newUsers.some((user: any) => user.email)) {
          dispatch(
            addWhitelistAction({
              email:
                e.data.AddEmailsInAuthorizedUsers.newUsers[0].email ||
                e.data.AddEmailsInAuthorizedUsers.toUnBlock[0].email,
              source: t('whitelist.manuel.column'),
              id:
                e.data.AddEmailsInAuthorizedUsers.newUsers[0].id ||
                e.data.AddEmailsInAuthorizedUsers.toUnBlock[0].id,
            })
          );
          setDisplayAddEmail(true);
        }
      });

    setNewEmail('');
  };

  return displayAddEmail ? (
    <div
      className={classes.addEmail}
      onClick={() => {
        setDisplayAddEmail(false);
        createEvent('add_new_employee_pressed');
      }}
    >
      <AddIcon />
      <p>{t('whitelist.current.addEmail.label')}</p>
    </div>
  ) : (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleAddEmail(newEmail);
        createEvent('add_new_employee_validated');
      }}
      className={classes.validateNewEmail}
    >
      <Input
        type="email"
        disableUnderline
        value={newEmail}
        onChange={e => setNewEmail(e.target.value)}
      />
      <MuiButton
        color="primary"
        onClick={() => {
          setNewEmail('');
          setDisplayAddEmail(true);
          setDisplaySnackbar(false);
        }}
        type="button"
      >
        {t('whitelist.current.addEmail.cancel')}
      </MuiButton>
      <MuiButton color="primary" disabled={!EMAIL_REGEX.test(newEmail)} type="submit">
        {t('whitelist.current.addEmail.add')}
      </MuiButton>
      <Snackbar
        status="error"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={displaySnackbar}
        onClose={() => setDisplaySnackbar(false)}
        message={t('whitelist.current.addEmail.duplicate')}
      />
    </form>
  );
};

interface EnhancedTableHeadProps {
  numSelected: number;
  setSelected: any;
  whitelist: { [key: string]: any }[];
  integrationType: any;
  setIntegrationType: any;
  company: any;
}

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const classes = useTableHead();
  const t = useTranslate();
  const {
    numSelected,
    setSelected,
    whitelist,
    integrationType,
    setIntegrationType,
    company,
  } = props;

  const [open, setOpen] = useState(false);

  const handleChange = (selectedOption: string) => {
    if (integrationType.includes(selectedOption)) {
      setIntegrationType(
        integrationType.filter((item: string) => item !== selectedOption)
      );
    } else {
      setIntegrationType(integrationType.concat(selectedOption));
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setSelected(whitelist);
    else setSelected([]);
  };

  const integrationTypes = [
    { label: t('whitelist.GoogleWorkspace.column'), value: 'GOOGLE_ADMIN_API' },
    { label: t('whitelist.AmazonS3.column'), value: 'AMAZON_S3' },
    { label: t('whitelist.fromDomain.column'), value: 'DOMAIN' },
    { label: t('whitelist.fromAPI.column'), value: 'FROM_API' },
    { label: t('whitelist.manuel.column'), value: 'MANUAL' },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < whitelist.length}
            checked={whitelist.length > 0 && numSelected === whitelist.length}
            onChange={handleSelectAllClick}
          />
        </TableCell>
        <TableCell className={classes.cells}>
          {t('whitelist.current.table.email.label')}
        </TableCell>
        {company.showIdentifiersOnDashboard && (
          <TableCell align="center" className={classes.cells}>
            {t('whitelist.current.table.refKey.label')}
          </TableCell>
        )}
        <TableCell align="right" className={classes.sourceCells}>
          <div className={classes.print}>
            <div className={classes.sourceCellsTitle}>
              <span>{t('whitelist.current.table.source.label')}</span>
              <ArrowDropDownIcon className={classes.arrow} onClick={handleClick} />
            </div>

            <div className={classes.listSource}>
              {integrationTypes.map(option => (
                <FormControlLabel
                  key={option.value}
                  label={<span className={classes.policeCheckbox}>{option.label}</span>}
                  control={
                    <Checkbox
                      color="primary"
                      checked={integrationType.includes(option.value)}
                      onChange={() => {
                        handleChange(option.value);
                      }}
                      className={classes.checkboxActive}
                    />
                  }
                />
              ))}
            </div>
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableToolbarProps {
  selected: { [key: string]: any }[];
  search: string;
  setDisplayDialog: any;
  setSearch: any;
  whitelist: { [key: string]: any }[];
  setDeletedEmails: any;
  setReferer: any;
  setAddedEmails: any;
  setError: any;
  uploadAuthorizedUsers: any;
  setNoChangeInCSV: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const t = useTranslate();
  const {
    selected,
    search,
    setDisplayDialog,
    setSearch,
    setDeletedEmails,
    setReferer,
    setAddedEmails,
    setError,
    uploadAuthorizedUsers,
    setNoChangeInCSV,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasIntegration, setHasIntegration] = useState(false);

  useEffect(() => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
      credentials: 'include',
      body: JSON.stringify({
        variables: { id: currentCompany.id },
        query: `query ExternalIntegrationGoogleGetOne($id: ID!) { data: ExternalIntegrationGoogleGetOne(id: $id) { id } }`,
      }),
    })
      .then(result => result.json())
      .then(e => {
        if (e.data.data && e.data.data.id) {
          setHasIntegration(true);
        }
        console.error(e);
      });
  }, []);

  const uploadCSV = (e: ChangeEvent<HTMLInputElement>) => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    convertFromCSV(e!.target!.files![0], {
      skipEmptyLines: true,
      complete: (results: { data: [][] }) => {
        if (results.data.length === 1 || results.data.length === 0) {
          setError(true);
          setDisplayDialog(true);
          return;
        }

        uploadAuthorizedUsers.current = whitelistCleaner(results.data);
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
          credentials: 'include',

          body: JSON.stringify({
            variables: {
              id: currentCompany.id,
              emails: uploadAuthorizedUsers.current,
            },
            query: `
              mutation ComputeAuthorizedUsers {
                ComputeAuthorizedUsers(input: {
                  companyID: "${currentCompany.id}",
                  emails: [
                    ${uploadAuthorizedUsers.current
                      .map((email: any) => `"${email}"`)
                      .join(',')}
                  ]
                }) {
                  newUsers {
                    id
                    email
                  }
                  toBlock {
                    id
                    email
                  }
                  toUnBlock {
                    id
                    email
                  }
                }
              }
            `,
          }),
        })
          .then(result => result.json())
          .then(e => {
            if (
              !e.data.ComputeAuthorizedUsers.newUsers.length &&
              !e.data.ComputeAuthorizedUsers.toBlock.length &&
              !e.data.ComputeAuthorizedUsers.toUnBlock.length
            ) {
              setDeletedEmails([]);
              setAddedEmails([]);
              setNoChangeInCSV(true);
            }
            setAddedEmails(
              e.data.ComputeAuthorizedUsers.newUsers.concat(
                e.data.ComputeAuthorizedUsers.toUnBlock
              )
            );
            setDeletedEmails(e.data.ComputeAuthorizedUsers.toBlock);
          });
        setDisplayDialog(true);
      },
    });
    // Reset the input file to trigger onChange if same whitelist is given
    e.target.value = '';
  };

  const handleDeleteClick = () => {
    setAddedEmails([]);
    setDeletedEmails([]);
    setReferer(Referer.DELETED);
    setDeletedEmails(selected);
    setDisplayDialog(true);
  };

  return selected.length > 0 ? (
    <Toolbar className={classes.selectedTitle}>
      <Typography component="div">
        {t('whitelist.current.toolbar.selected', { smart_count: selected.length })}
      </Typography>
      <Tooltip title="Delete">
        <IconButton aria-label="delete" onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  ) : (
    <Toolbar className={classes.root}>
      <h3>{t('whitelist.current.toolbar.title')}</h3>
      <label className={classes.searchLabel}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          type="search"
          placeholder={t('whitelist.current.search.placeholder')}
          disableUnderline
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </label>

      <Button
        color="primary"
        onClick={() => {
          setSearch('');
          setError(false);
          createEvent('update_whitelist_pressed');
          setReferer(Referer.UPLOAD_CSV);
        }}
        variant="contained"
        endIcon={<CachedIcon fontSize="large" />}
      >
        <label htmlFor="whitelistUpload">
          <input
            onInput={uploadCSV}
            accept=".csv"
            type="file"
            id="whitelistUpload"
            name="whitelistUpload"
            style={{ display: 'none' }}
          />
          <span>{t('whitelist.current.updateButton.label')}</span>
        </label>
      </Button>

      {/* {hasIntegration === false ? (
        <Button
          color="primary"
          onClick={() => {
            setSearch('');
            setError(false);
            setError(true);
            setReferer(Referer.UPLOAD_CSV);
          }}
          variant="contained"
          endIcon={<CachedIcon fontSize="large" />}
        >
          <label htmlFor="whitelistUpload">
            <input
              onInput={uploadCSV}
              accept=".csv"
              type="file"
              id="whitelistUpload"
              name="whitelistUpload"
              style={{ display: 'none' }}
            />
            <span>{t('whitelist.current.updateButton.label')}</span>
          </label>
        </Button>
      ) : (
        <Button color="primary" variant="contained">
          <label>
            <span>{t('whitelist.current.synchronizedList.label')}</span>
          </label>
        </Button>
      )} */}
    </Toolbar>
  );
};

const CurrentWhitelist = ({
  company,
  whitelist,
  fetchData,
}: {
  company: { [key: string]: any };
  whitelist: { [key: string]: any }[];
  fetchData: (
    page: number,
    perPage: number,
    search?: string,
    integrationType?: string
  ) => void;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  // eslint-disable-next-line
  const [newWhitelist, setNewWhitelist] = useState((null as unknown) as any[]);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selected, setSelected] = useState<any>([]);
  const total: number = useSelector(
    (state: any) => state.whitelistPaginatedReducers.total
  );
  const dataProvider = useDataProvider();
  // eslint-disable-next-line
  const [addedEmails, setAddedEmails] = useState([]);
  const [deletedEmails, setDeletedEmails] = useState<any>([]);

  const previousPage = useRef(page);
  const previousSearch = useRef(search);
  const previousPerPage = useRef(perPage);
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const [integrationType, setIntegrationType] = useState<any>([]);
  const previousSelectedOptions = useRef(integrationType);
  const [referer, setReferer] = useState('');
  const [error, setError] = useState(false);
  const uploadAuthorizedUsers = useRef([]);
  const [emailExist, setEmailExist] = useState(false);
  const [noChangeInCSV, setNoChangeInCSV] = useState(false);

  const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);

  const exportCSV = () => {
    createEvent('download_whitelist_pressed');
    dataProvider
      .getOne('CorporateCompany', {
        id: currentCompany.id,
        search,
        page: -1,
        integrationType,
      })
      .then((e: any) => {
        const csv = convertToCSV({
          data: e.data.map((e: any) => [
            e.email,
            e.fromGoogle
              ? t('whitelist.GoogleWorkspace.column')
              : e.fromAmazonS3
              ? t('whitelist.AmazonS3.column')
              : e.fromDomain
              ? t('whitelist.fromDomain.column')
              : e.fromAPI
              ? t('whitelist.fromAPI.column')
              : t('whitelist.manuel.column'),
            e.refKey,
          ]),
          fields: [
            t('whitelist.current.table.email.label'),
            t('whitelist.current.table.source.label'),
            t('whitelist.current.table.refKey.label'),
          ],
        });
        downloadCSV(csv, 'whitelist');
      });
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      setDisplaySnackbar(true);
    });
  };

  const handleSelectClick = (
    event: React.MouseEvent<unknown>,
    email: { [key: string]: any }
  ) => {
    const selectedIndex = selected.findIndex((e: any) => e.id === email.id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (!newSelected.length) {
      setDeletedEmails([]);
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.find((e: any) => e.id === id) !== undefined;

  const debounceFetchData = useMemo(() => debounce(fetchData, 500, { leading: false }), [
    fetchData,
  ]);

  useEffect(() => {
    if (page !== previousPage.current) {
      previousPage.current = page;
      debounceFetchData(page, perPage, search, integrationType);
    }

    if (perPage !== previousPerPage.current) {
      previousPerPage.current = perPage;
      debounceFetchData(page, perPage, search, integrationType);
    }

    if (search !== previousSearch.current) {
      previousSearch.current = search;
      debounceFetchData(0, perPage, search, integrationType);
    }

    if (integrationType !== previousSelectedOptions.current) {
      previousSelectedOptions.current = integrationType;
      debounceFetchData(0, perPage, search, integrationType);
    }
  }, [search, page, perPage, integrationType, debounceFetchData]);

  return (
    <Paper>
      <EnhancedTableToolbar
        selected={selected}
        whitelist={whitelist}
        search={search}
        setDisplayDialog={setDisplayDialog}
        setSearch={setSearch}
        setDeletedEmails={setDeletedEmails}
        setReferer={setReferer}
        setAddedEmails={setAddedEmails}
        setError={setError}
        setNoChangeInCSV={setNoChangeInCSV}
        uploadAuthorizedUsers={uploadAuthorizedUsers}
      />

      <Table>
        <EnhancedTableHead
          whitelist={whitelist}
          numSelected={selected.length}
          setSelected={setSelected}
          integrationType={integrationType}
          setIntegrationType={setIntegrationType}
          company={company}
        />
        <TableBody>
          {whitelist.map((email: { [key: string]: any }, index) => (
            <TableRow key={email.id} selected={isSelected(email.id)}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelected(email.id)}
                  color="primary"
                  onClick={event => handleSelectClick(event, email)}
                />
              </TableCell>
              <TableCell align="left">{email.email}</TableCell>
              {company.showIdentifiersOnDashboard && (
                <TableCell align="center">
                  {email.refKey && email.refKey.length > 40
                    ? email.refKey.slice(0, 40) + '...'
                    : email.refKey}
                </TableCell>
              )}
              <TableCell align="right">
                {email.fromGoogle
                  ? t('whitelist.GoogleWorkspace.column')
                  : email.fromAmazonS3
                  ? t('whitelist.AmazonS3.column')
                  : email.fromDomain
                  ? t('whitelist.fromDomain.column')
                  : email.fromAPI
                  ? t('whitelist.fromAPI.column')
                  : t('whitelist.manuel.column')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EnhancedAddEmail setEmailExist={setEmailExist} />
      <div className={classes.tableFooter}>
        <MuiButton
          className={classes.downloadButton}
          color="primary"
          endIcon={<CloudDownloadIcon fontSize="large" />}
          onClick={exportCSV}
        >
          {t('whitelist.current.downloadButton.label')}
        </MuiButton>
        {company.showIdentifiersOnDashboard && company.companyInvitationLink && (
          <MuiButton
            className={classes.downloadButton}
            color="primary"
            endIcon={<Link fontSize="large" />}
            onClick={() => copyToClipboard(company.companyInvitationLink)}
          >
            {t('whitelist.current.copyLinkButton.label')}
          </MuiButton>
        )}
        <TablePagination
          component="div"
          count={total}
          onPageChange={(_, page) => setPage(page)}
          labelRowsPerPage={t('whitelist.current.rowsPerPageLabel')}
          onRowsPerPageChange={e => setPerPage(parseInt(e!.target!.value))}
          page={page}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={perPage}
        />
      </div>
      <UpdateWhitelistDialog
        companyID={currentCompany.id}
        displayDialog={displayDialog}
        handleClose={() => {
          setDeletedEmails([]);
          setAddedEmails([]);
          setDisplayDialog(false);
        }}
        addedEmails={addedEmails}
        deletedEmails={deletedEmails}
        total={total}
        setSelected={setSelected}
        fetchData={fetchData}
        referer={referer}
        uploadAuthorizedUsers={uploadAuthorizedUsers}
        setIntegrationType={setIntegrationType}
        setSearch={setSearch}
        setPage={setPage}
      />
      {error && (
        <Snackbar
          status="error"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={error}
          onClose={() => setError(false)}
          message={t('whitelist.current.whitelistEmpty.error')}
        />
      )}
      <Snackbar
        status="success"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={displaySnackbar}
        onClose={() => setDisplaySnackbar(false)}
        message={t('whitelist.current.copyLink.success')}
      />
      {emailExist && (
        <Snackbar
          status="error"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={emailExist}
          onClose={() => setEmailExist(false)}
          message={t('whitelist.current.addEmail.duplicate')}
        />
      )}
      {noChangeInCSV && (
        <Snackbar
          status="error"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={noChangeInCSV}
          onClose={() => setNoChangeInCSV(false)}
          message={t('whitelist.current.noChange.error')}
        />
      )}
    </Paper>
  );
};

export default CurrentWhitelist;
