import MuiButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Link from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import debounce from 'debounce-promise';
import { parse as convertFromCSV, unparse as convertToCSV } from 'papaparse';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { downloadCSV, useTranslate, useDataProvider } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import {
  AuthorizedUser,
  LocalCompany,
  SimplifiedAuthorizedUser,
} from '../../@types/common';
import { Button, Snackbar, Paper } from '../../components';
import { addAuthorizedUserWhitelistAction } from '../../redux/actions';
import { EMAIL_REGEX, cleanCSVWithRefKeyOutput, safeJSONParse } from '../../utils';
import { athensGray, blueGem, frenchGray, shark } from '../../utils/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { createEvent } from '../../utils/analytics';
import Maybe from 'graphql/tsutils/Maybe';
import UpdateWhitelistWithRefKeysDialog, {
  Referer,
} from './updateWhitelistWithRefKeysDialog';
import { apolloClient } from '../../utils/apolloClient';
import gql from 'graphql-tag';
import { WhitelistLoadingDialog } from './noWhitelist';

const useAddEmployee = makeStyles({
  addAuthorizedUser: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    color: shark,

    '&:hover': {
      backgroundColor: athensGray,
    },

    '& p': {
      margin: '16px 10px',
    },
  },
  cell: {
    padding: '10px 16px',
  },
  emailInput: {
    padding: 0,
    width: '100%',
    textAlign: 'center',
    '& input': {
      padding: 0,
      textAlign: 'center',
    },
  },
  refKeyInput: {
    padding: 0,
    width: '100%',
    textAlign: 'left',
    '& input': {
      textAlign: 'left',
      padding: 0,
    },
  },
  addEmail: {
    padding: '10px 0',
    borderLeft: `1px solid ${frenchGray}`,
    borderRight: `1px solid ${frenchGray}`,
  },
  validateNewEmail: {
    border: `1px solid ${blueGem}`,
    display: 'flex',
    padding: '8px',

    '& > :first-child': {
      flexGrow: 1,
      paddingLeft: '60px',
    },
    '& > :not(:first-child)': {
      padding: '0 25px',
    },
  },
});

const useTableHead = makeStyles({
  cells: {
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    cursor: 'pointer',
  },
  open: {
    display: 'block',
  },
  listSource: {
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '2px solid #3C0CBF',
    padding: '12px 8px',
    gap: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    borderRadius: '8px',
    right: '10px',
    zIndex: 2,
    width: '183px',
    height: '236px',
  },
  sourceCellsTitle: {
    display: 'flex',
    justifyContent: 'right',
  },
  sourceCells: {
    fontWeight: 'bold',
    position: 'relative',
  },
  checkboxActive: {
    '&:checked': {
      backgroundColor: '#3C0CBF',
    },
  },
  policeCheckbox: {
    fontFamily: 'Poppins',
    width: '175px',
    height: '26px',
    fontStyle: 'normal',
    lineHeight: '26px',
    display: 'flex',
    alignItems: 'center',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  print: {
    '&:hover $listSource': {
      display: 'flex',
    },
  },
});

const useToolbarStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',

    '& > :first-child': {
      flexGrow: 1,
    },
  },
  searchIcon: {
    marginRight: '5px',
  },
  searchLabel: {
    padding: '2px 10px',
    margin: 'auto 10px',
    display: 'inline-flex',
    maxWidth: '200px',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid black',
  },
  selectedTitle: {
    alignItems: 'center',
    display: 'flex',
    background: athensGray,
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',

    '& > :first-child': {
      flexGrow: 1,
      fontWeight: 'bold',
    },
  },
  uploadButton: {
    letterSpacing: '0.75px',
    borderRadius: '20px',
    whiteSpace: 'nowrap',
  },
});

const useStyles = makeStyles({
  downloadButton: {
    borderRadius: '20px',
    margin: '15px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  tableFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  textOverflow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const ADD_AUTHORIZED_USERS_MUTATION = gql`
  mutation AddAuthorizedUsers($input: AuthorizedEmployeesInput!) {
    AddAuthorizedUsers(input: $input) {
      newUsersAsString
      toUnBlockAsString
    }
  }
`;
const addAuthorizedUsers = (companyID: string, authorizedUsersAsString: string) => {
  return apolloClient.mutate({
    mutation: ADD_AUTHORIZED_USERS_MUTATION,
    variables: {
      input: {
        companyID,
        authorizedUsersAsString,
      },
    },
  });
};

interface EnhancedAddEmployeeProps {
  setEmployeeExists: any;
  usersCanRegisterWithIdentifier?: boolean;
}

const EnhancedAddEmployee = (props: EnhancedAddEmployeeProps) => {
  const { setEmployeeExists } = props;
  const classes = useAddEmployee();
  const dispatch = useDispatch();
  const t = useTranslate();
  const [displayAddEmployee, setDisplayAddEmployee] = useState(true);
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newRefKey, setNewRefKey] = useState('');
  const sanitizedString = (str: string) => str.replace(/\s+/g, '');

  const enableSubmitButton = () => {
    return (
      sanitizedString(newRefKey) &&
      (newEmail ? EMAIL_REGEX.test(sanitizedString(newEmail)) : true)
    );
  };

  const handleAddAuthorizedUser = (authorizedUser: SimplifiedAuthorizedUser) => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );

    const stringifiedAuthorizedUsers = JSON.stringify([authorizedUser]);
    addAuthorizedUsers(currentCompany.id, stringifiedAuthorizedUsers)
      .then(result => {
        let newUsers = [];
        let toUnBlock = [];
        if (result.data && result.data.AddAuthorizedUsers) {
          newUsers = safeJSONParse(result.data.AddAuthorizedUsers.newUsersAsString);
          toUnBlock = safeJSONParse(result.data.AddAuthorizedUsers.toUnBlockAsString);

          if (newUsers && newUsers.length === 0) {
            setEmployeeExists(true);
          }
        }
        if (newUsers.length > 0 || toUnBlock.length > 0) {
          dispatch(
            addAuthorizedUserWhitelistAction({
              email: newUsers[0]?.email || toUnBlock[0]?.email,
              refKey: newUsers[0]?.refKey || toUnBlock[0]?.refKey,
              source: t('whitelist.manuel.column'),
              id: newUsers[0]?.id || toUnBlock[0]?.id,
            })
          );
          setDisplayAddEmployee(true);
        }
      })
      .catch(error => {
        console.error(error);
      });

    setNewEmail('');
    setNewRefKey('');
  };

  return displayAddEmployee ? (
    <TableCell colSpan={4}>
      <div
        className={classes.addAuthorizedUser}
        onClick={() => {
          setDisplayAddEmployee(false);
          createEvent('add_new_employee_pressed');
        }}
      >
        <AddIcon />
        <p>{t('whitelist.current.addEmail.label')}</p>
      </div>
    </TableCell>
  ) : (
    <>
      <TableCell className={classes.cell}></TableCell>
      <TableCell align="left">
        <Input
          type="text"
          className={classes.refKeyInput}
          disableUnderline
          value={newRefKey}
          onChange={e => setNewRefKey(e.target.value)}
          autoFocus
        />
      </TableCell>
      <TableCell align="center" className={`${classes.cell} ${classes.addEmail}`}>
        <Input
          className={classes.emailInput}
          type="email"
          disableUnderline
          value={newEmail}
          onChange={e => setNewEmail(e.target.value)}
          autoFocus={!props.usersCanRegisterWithIdentifier}
        />
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        <MuiButton
          color="primary"
          onClick={() => {
            setNewEmail('');
            setNewRefKey('');
            setDisplayAddEmployee(true);
            setDisplaySnackbar(false);
          }}
          type="button"
        >
          {t('whitelist.current.addEmail.cancel')}
        </MuiButton>
        <MuiButton
          color="primary"
          disabled={!enableSubmitButton()}
          type="submit"
          onClick={() => {
            handleAddAuthorizedUser({ email: newEmail, refKey: newRefKey });
            createEvent('add_new_employee_validated');
          }}
        >
          {t('whitelist.current.addEmail.add')}
        </MuiButton>
        <Snackbar
          status="error"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={displaySnackbar}
          onClose={() => setDisplaySnackbar(false)}
          message={t('whitelist.current.addEmail.duplicate')}
        />
      </TableCell>
    </>
  );
};

interface EnhancedTableHeadProps {
  numSelected: number;
  setSelected: any;
  whitelist: { [key: string]: any }[];
  integrationType: any;
  setIntegrationType: any;
  company: any;
}

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const classes = useTableHead();
  const t = useTranslate();
  const {
    numSelected,
    setSelected,
    whitelist,
    integrationType,
    setIntegrationType,
  } = props;

  const [open, setOpen] = useState(false);

  const handleChange = (selectedOption: string) => {
    if (integrationType.includes(selectedOption)) {
      setIntegrationType(
        integrationType.filter((item: string) => item !== selectedOption)
      );
    } else {
      setIntegrationType(integrationType.concat(selectedOption));
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setSelected(whitelist);
    else setSelected([]);
  };

  const integrationTypes = [
    { label: t('whitelist.GoogleWorkspace.column'), value: 'GOOGLE_ADMIN_API' },
    { label: t('whitelist.AmazonS3.column'), value: 'AMAZON_S3' },
    { label: t('whitelist.fromDomain.column'), value: 'DOMAIN' },
    { label: t('whitelist.fromAPI.column'), value: 'FROM_API' },
    { label: t('whitelist.manuel.column'), value: 'MANUAL' },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < whitelist.length}
            checked={whitelist.length > 0 && numSelected === whitelist.length}
            onChange={handleSelectAllClick}
          />
        </TableCell>
        <TableCell align="left" className={classes.cells}>
          {t('whitelist.current.table.refKey.label')}
        </TableCell>
        <TableCell align="center" className={classes.cells}>
          {t('whitelist.current.table.email.label')}
        </TableCell>
        <TableCell align="right" className={classes.sourceCells}>
          <div className={classes.print}>
            <div className={classes.sourceCellsTitle}>
              <span>{t('whitelist.current.table.source.label')}</span>
              <ArrowDropDownIcon className={classes.arrow} onClick={handleClick} />
            </div>

            <div className={classes.listSource}>
              {integrationTypes.map(option => (
                <FormControlLabel
                  key={option.value}
                  label={<span className={classes.policeCheckbox}>{option.label}</span>}
                  control={
                    <Checkbox
                      color="primary"
                      checked={integrationType.includes(option.value)}
                      onChange={() => {
                        handleChange(option.value);
                      }}
                      className={classes.checkboxActive}
                    />
                  }
                />
              ))}
            </div>
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableToolbarProps {
  selected: AuthorizedUser[];
  search: string;
  setDisplayDialog: Function;
  setSearch: Function;
  whitelist: AuthorizedUser[];
  setDeletedAuthorizedUsers: Function;
  setReferer: Function;
  setAddedAuthorizedUsers: Function;
  setError: Function;
  uploadAuthorizedUsers: any;
  setNoChangeInCSV: Function;
}

const COMPUTE_AUTHORIZED_USERS_MUTATION = gql`
  mutation ComputeAuthorizedUsers($input: ComputeAuthorizedUsersInput) {
    ComputeAuthorizedUsers(input: $input) {
      newUsersAsString
      toBlockAsString
      toUnBlockAsString
    }
  }
`;
const computeAuthorizedUsers = (companyID: string, authorizedUsers: string) => {
  return apolloClient.mutate({
    mutation: COMPUTE_AUTHORIZED_USERS_MUTATION,
    variables: {
      input: {
        companyID,
        authorizedUsers,
      },
    },
  });
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const [createWhitelistLoadingDialog, setCreateWhitelistLoadingDialog] = useState(false);
  const [authorizedUsersCount, setAuthorizedUsersCount] = useState(0);

  const classes = useToolbarStyles();
  const t = useTranslate();
  const {
    selected,
    search,
    setDisplayDialog,
    setSearch,
    setDeletedAuthorizedUsers,
    setReferer,
    setAddedAuthorizedUsers,
    setError,
    uploadAuthorizedUsers,
    setNoChangeInCSV,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasIntegration, setHasIntegration] = useState(false);

  useEffect(() => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );
    fetch(`${process.env.REACT_APP_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
      credentials: 'include',
      body: JSON.stringify({
        variables: { id: currentCompany.id },
        query: `query ExternalIntegrationGoogleGetOne($id: ID!) { data: ExternalIntegrationGoogleGetOne(id: $id) { id } }`,
      }),
    })
      .then(result => result.json())
      .then(e => {
        if (e.data.data && e.data.data.id) {
          setHasIntegration(true);
        }
        console.error(e);
      });
  }, []);

  const overrideUserEmailsWithCsvValues = (users: any[], csvValues: any[]) => {
    const usersMap = new Map<string, any>();
    const csvValuesMap = new Map<string, any>();
    csvValues.forEach((csvUser: any) => {
      csvValuesMap.set(csvUser.refKey, csvUser);
    });

    users.forEach((user: any) => {
      usersMap.set(user.refKey, {
        ...user,
        ...(csvValuesMap.get(user.refKey) || {}),
      });
    });

    const overriddenUsers = Array.from(usersMap).map(([refKey, user]) => user);
    return overriddenUsers;
  };

  const uploadCSV = (e: ChangeEvent<HTMLInputElement>) => {
    const currentCompany: LocalCompany = JSON.parse(
      localStorage.getItem('currentCompany') as string
    );
    convertFromCSV(e!.target!.files![0], {
      skipEmptyLines: true,
      complete: (results: { data: [Maybe<string>, Maybe<string>][] }) => {
        if (results.data.length === 1 || results.data.length === 0) {
          setError(true);
          setDisplayDialog(true);
          return;
        }
        //remove first line as it is the header
        results.data.shift();
        uploadAuthorizedUsers.current = cleanCSVWithRefKeyOutput(results.data);
        const stringifiedAuthorizedUsers = JSON.stringify(uploadAuthorizedUsers.current);
        try {
          setCreateWhitelistLoadingDialog(true);
          setAuthorizedUsersCount(uploadAuthorizedUsers.current?.length || 0);
          computeAuthorizedUsers(currentCompany.id, stringifiedAuthorizedUsers).then(
            result => {
              let newUsers = safeJSONParse(
                result.data.ComputeAuthorizedUsers.newUsersAsString
              );
              let toBlock = safeJSONParse(
                result.data.ComputeAuthorizedUsers.toBlockAsString
              );
              const toUnBlock = safeJSONParse(
                result.data.ComputeAuthorizedUsers.toUnBlockAsString
              );
              if (!newUsers.length && !toBlock.length && !toUnBlock.length) {
                setDeletedAuthorizedUsers([]);
                setAddedAuthorizedUsers([]);
                setNoChangeInCSV(true);
              }
              if (toUnBlock.length > 0) {
                newUsers = newUsers.concat(toUnBlock);
              }
              //override newUsers with CSV values as we wanted to show updated emails
              newUsers = overrideUserEmailsWithCsvValues(
                newUsers,
                uploadAuthorizedUsers.current
              );
              toBlock = overrideUserEmailsWithCsvValues(
                toBlock,
                uploadAuthorizedUsers.current
              );

              setAddedAuthorizedUsers(newUsers);
              setDeletedAuthorizedUsers(toBlock);
              setCreateWhitelistLoadingDialog(false);
              setDisplayDialog(true);
            }
          );
        } catch (error) {
          setCreateWhitelistLoadingDialog(false);
          setAuthorizedUsersCount(0);
          console.error(error);
        }
      },
    });
    //Reset the input file to trigger onChange if same whitelist is given
    e.target.value = '';
  };

  const handleDeleteClick = () => {
    setAddedAuthorizedUsers([]);
    setDeletedAuthorizedUsers([]);
    setReferer(Referer.DELETED);
    setDeletedAuthorizedUsers(selected);
    setDisplayDialog(true);
  };

  return selected.length > 0 ? (
    <Toolbar className={classes.selectedTitle}>
      <Typography component="div">
        {t('whitelist.current.toolbar.selected', { smart_count: selected.length })}
      </Typography>
      <Tooltip title="Delete">
        <IconButton aria-label="delete" onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  ) : (
    <Toolbar className={classes.root}>
      <h3>{t('whitelist.current.toolbar.title')}</h3>
      <label className={classes.searchLabel}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          type="search"
          placeholder={t('whitelist.current.search.placeholder')}
          disableUnderline
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </label>

      <Button
        color="primary"
        onClick={() => {
          setSearch('');
          setError(false);
          createEvent('update_whitelist_pressed');
          setReferer(Referer.UPLOAD_CSV);
        }}
        variant="contained"
        endIcon={<CachedIcon fontSize="large" />}
      >
        <label htmlFor="whitelistUpload">
          <input
            onInput={uploadCSV}
            accept=".csv"
            type="file"
            id="whitelistUpload"
            name="whitelistUpload"
            style={{ display: 'none' }}
          />
          <span>{t('whitelist.current.updateButton.label')}</span>
        </label>
      </Button>
      {createWhitelistLoadingDialog && (
        <WhitelistLoadingDialog
          isOpen={createWhitelistLoadingDialog}
          usersCount={authorizedUsersCount || 0}
        />
      )}
    </Toolbar>
  );
};

const CurrentWhitelistWithRefKeys = ({
  company,
  whitelist,
  fetchData,
}: {
  company: { [key: string]: any };
  whitelist: AuthorizedUser[];
  fetchData: (
    page: number,
    perPage: number,
    search?: string,
    integrationType?: string
  ) => void;
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  // eslint-disable-next-line
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selected, setSelected] = useState<any>([]);
  const total: number = useSelector(
    (state: any) => state.whitelistPaginatedReducers.total
  );
  const dataProvider = useDataProvider();
  // eslint-disable-next-line
  const [addedAuthorizedUsers, setAddedAuthorizedUsers] = useState<AuthorizedUser[]>([]);
  const [deletedAuthorizedUsers, setDeletedAuthorizedUsers] = useState<AuthorizedUser[]>(
    []
  );

  const previousPage = useRef(page);
  const previousSearch = useRef(search);
  const previousPerPage = useRef(perPage);
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const [integrationType, setIntegrationType] = useState<any>([]);
  const previousSelectedOptions = useRef(integrationType);
  const [referer, setReferer] = useState('');
  const [error, setError] = useState(false);
  const uploadAuthorizedUsers = useRef([]);
  const [employeeExists, setEmployeeExists] = useState(false);
  const [noChangeInCSV, setNoChangeInCSV] = useState(false);
  const [createWhitelistLoadingDialog, setCreateWhitelistLoadingDialog] = useState(false);

  const currentCompany = JSON.parse(localStorage.getItem('currentCompany') as string);

  const exportCSV = () => {
    createEvent('download_whitelist_pressed');
    setCreateWhitelistLoadingDialog(true);
    dataProvider
      .getOne('CorporateCompany', {
        id: currentCompany.id,
        search,
        page: -1,
        integrationType,
        responseAsString: true,
      })
      .then((e: any) => {
        const csv = convertToCSV({
          data: e.data.map((e: any) => [
            e.refKey,
            e.email,
            e.fromGoogle
              ? t('whitelist.GoogleWorkspace.column')
              : e.fromAmazonS3
              ? t('whitelist.AmazonS3.column')
              : e.fromDomain
              ? t('whitelist.fromDomain.column')
              : e.fromAPI
              ? t('whitelist.fromAPI.column')
              : t('whitelist.manuel.column'),
          ]),
          fields: [
            t('whitelist.current.table.refKey.label'),
            t('whitelist.current.table.email.label'),
            t('whitelist.current.table.source.label'),
          ],
        });
        setCreateWhitelistLoadingDialog(false);
        downloadCSV(csv, 'whitelist');
      })
      .catch((error: any) => {
        setCreateWhitelistLoadingDialog(false);
        console.error('Error when downloading the CSV', error);
      });
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      setDisplaySnackbar(true);
    });
  };

  const handleSelectClick = (
    event: React.MouseEvent<unknown>,
    employee: AuthorizedUser
  ) => {
    const selectedIndex = selected.findIndex((e: any) => e.id === employee.id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, employee);
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (!newSelected.length) {
      setDeletedAuthorizedUsers([]);
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.find((e: any) => e.id === id) !== undefined;

  const debounceFetchData = useMemo(() => debounce(fetchData, 500, { leading: false }), [
    fetchData,
  ]);

  useEffect(() => {
    if (page !== previousPage.current) {
      previousPage.current = page;
      debounceFetchData(page, perPage, search, integrationType);
    }

    if (perPage !== previousPerPage.current) {
      previousPerPage.current = perPage;
      debounceFetchData(page, perPage, search, integrationType);
    }

    if (search !== previousSearch.current) {
      previousSearch.current = search;
      debounceFetchData(0, perPage, search, integrationType);
    }

    if (integrationType !== previousSelectedOptions.current) {
      previousSelectedOptions.current = integrationType;
      debounceFetchData(0, perPage, search, integrationType);
    }
  }, [search, page, perPage, integrationType, debounceFetchData]);

  return (
    <Paper>
      <EnhancedTableToolbar
        selected={selected}
        whitelist={whitelist}
        search={search}
        setDisplayDialog={setDisplayDialog}
        setSearch={setSearch}
        setDeletedAuthorizedUsers={setDeletedAuthorizedUsers}
        setReferer={setReferer}
        setAddedAuthorizedUsers={setAddedAuthorizedUsers}
        setError={setError}
        setNoChangeInCSV={setNoChangeInCSV}
        uploadAuthorizedUsers={uploadAuthorizedUsers}
      />

      <Table>
        <EnhancedTableHead
          whitelist={whitelist}
          numSelected={selected.length}
          setSelected={setSelected}
          integrationType={integrationType}
          setIntegrationType={setIntegrationType}
          company={company}
        />
        <TableBody>
          {whitelist.map((authorizedUser, index) => (
            <TableRow key={index} selected={isSelected(authorizedUser.id)}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelected(authorizedUser.id)}
                  color="primary"
                  onClick={event =>
                    handleSelectClick(event, authorizedUser as AuthorizedUser)
                  }
                />
              </TableCell>
              <TableCell align="left" className={classes.textOverflow}>
                {authorizedUser.refKey}
              </TableCell>
              <TableCell align="center" className={classes.textOverflow}>
                {authorizedUser.email}
              </TableCell>

              <TableCell align="right">
                {authorizedUser.fromGoogle
                  ? t('whitelist.GoogleWorkspace.column')
                  : authorizedUser.fromAmazonS3
                  ? t('whitelist.AmazonS3.column')
                  : authorizedUser.fromDomain
                  ? t('whitelist.fromDomain.column')
                  : authorizedUser.fromAPI
                  ? t('whitelist.fromAPI.column')
                  : t('whitelist.manuel.column')}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <EnhancedAddEmployee
              setEmployeeExists={setEmployeeExists}
              usersCanRegisterWithIdentifier={company.usersCanRegisterWithIdentifier}
            />
          </TableRow>
        </TableBody>
      </Table>
      <div className={classes.tableFooter}>
        <MuiButton
          className={classes.downloadButton}
          color="primary"
          endIcon={<CloudDownloadIcon fontSize="large" />}
          onClick={exportCSV}
        >
          {t('whitelist.current.downloadButton.label')}
        </MuiButton>
        {company.showIdentifiersOnDashboard && company.companyInvitationLink && (
          <MuiButton
            className={classes.downloadButton}
            color="primary"
            endIcon={<Link fontSize="large" />}
            onClick={() => copyToClipboard(company.companyInvitationLink)}
          >
            {t('whitelist.current.copyLinkButton.label')}
          </MuiButton>
        )}
        <TablePagination
          component="div"
          count={total}
          onPageChange={(_, page) => setPage(page)}
          labelRowsPerPage={t('whitelist.current.rowsPerPageLabel')}
          onRowsPerPageChange={e => setPerPage(parseInt(e!.target!.value))}
          page={page}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={perPage}
        />
      </div>
      <UpdateWhitelistWithRefKeysDialog
        companyID={currentCompany.id}
        displayDialog={displayDialog}
        handleClose={() => {
          setDeletedAuthorizedUsers([]);
          setAddedAuthorizedUsers([]);
          setDisplayDialog(false);
        }}
        addedAuthorizedUsers={addedAuthorizedUsers}
        deletedAuthorizedUsers={deletedAuthorizedUsers}
        total={total}
        setSelected={setSelected}
        fetchData={fetchData}
        referer={referer}
        uploadAuthorizedUsers={uploadAuthorizedUsers}
        setIntegrationType={setIntegrationType}
        setSearch={setSearch}
        setPage={setPage}
      />
      {error && (
        <Snackbar
          status="error"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={error}
          onClose={() => setError(false)}
          message={t('whitelist.current.whitelistEmpty.error')}
        />
      )}
      <Snackbar
        status="success"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={displaySnackbar}
        onClose={() => setDisplaySnackbar(false)}
        message={t('whitelist.current.copyLink.success')}
      />
      {employeeExists && (
        <Snackbar
          status="error"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={employeeExists}
          onClose={() => setEmployeeExists(false)}
          message={t('whitelist.current.addEmail.duplicate')}
        />
      )}
      {noChangeInCSV && (
        <Snackbar
          status="error"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={noChangeInCSV}
          onClose={() => setNoChangeInCSV(false)}
          message={t('whitelist.current.noChange.error')}
        />
      )}
      {createWhitelistLoadingDialog && (
        <WhitelistLoadingDialog
          isOpen={createWhitelistLoadingDialog}
          usersCount={total || 0}
        />
      )}
    </Paper>
  );
};

export default CurrentWhitelistWithRefKeys;
