import React, { useEffect, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/styles/makeStyles';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import MuiButton from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate, useDataProvider, useRefresh } from 'react-admin';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  alto,
  athensGray,
  frenchGray,
  funGreen,
  grannyApple,
  neonCarott,
  santasGray,
  serenade,
  white,
} from '../../utils/colors';
import DeleteChallengeDialog from './deleteChallengeDialog';
import { useParams, useHistory } from 'react-router-dom';
import PublishChallengeDialog from './publishChallengeDialog';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import { updateChallengeAction } from '../../redux/actions';
import { EditableHeader, Snackbar } from '../../components';
import { SnackbarStatus } from '../../components/Snackbar';
import { Close } from '@material-ui/icons';
import Spinner from '../../components/Spinner';
import { Championships } from './championships';
import { ChallengeStatus, Manager } from '../../@types/common';

const AUTOHIDE_UNDO_SNACKBAR = 4000;
const ROWS_PER_PAGE = 15;

const useStyles = makeStyles({
  mainWrapper: { padding: '0 32px' },
  bodyWrapper: { display: 'flex', flexDirection: 'row' },
  badge: {
    borderRadius: '100px',
    display: 'inline-block',
    padding: '6px 12px',
    marginBottom: '9px',
    marginTop: 0,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 20,
    margin: '0 0 16px 0',
  },
  headerContainer: {
    padding: '24px',
  },
  datePicker: {
    margin: '0 0 16px 0',
    backgroundColor: athensGray,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px 0 8px',
    '& >:first-child': {
      marginRight: '10px',
      color: frenchGray,
    },
  },
  infosContainer: {
    padding: '24px',
    width: '440px',
    minWidth: '440px',
    borderTop: `1px solid ${athensGray}`,
  },
  championshipsContainer: {
    flexGrow: 1,
    borderTop: `1px solid ${athensGray}`,
    borderLeft: `1px solid ${athensGray}`,
  },
  championshipInfos: { padding: '24px' },
  descriptionContainer: {
    marginTop: '16px',
    fontWeight: 'normal',
    fontSize: '14px',
    '& > p': {
      margin: 0,
      '&:first-child': {
        marginBottom: '32px',
      },
    },
  },
  headerChampionshipName: { width: '100%' },
  cellChampionShipName: {
    borderRight: `1px solid ${athensGray}`,
    color: '#212936',
    display: 'flex',
    justifyContent: 'space-between',
  },
  showTeamsLabel: {
    color: '#525268',
    fontSize: '12px',
    cursor: 'pointer',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > .title': {
      margin: 0,
      flexGrow: 1,
      fontWeight: 500,
      fontSize: '34px',
    },
    '& .icon': {
      marginRight: '5px',
    },
  },
  deleteIcon: {
    marginRight: '10px',
    color: santasGray,
  },
  championshipsDescription: {
    color: santasGray,
    fontSize: '14px',
  },
  backBarWrapper: {
    padding: '24px 0',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 8,
    },
    '& > :last-child': {
      fontWeight: 500,
      fontSize: '14px',
      textTransform: 'uppercase',
    },
  },
  fileUploadContainer: {
    height: '250px',
    backgroundColor: '#f1f1f3',
    position: 'relative',
    borderRadius: '4px',
    display: 'flex',

    '& img': {
      width: '100%',
    },
  },
  fileUploadButton: {
    position: 'absolute',
    bottom: '12px',
    left: '12px',
    textTransform: 'uppercase',
    backgroundColor: white,

    '&:hover': {
      backgroundColor: athensGray,
    },
  },
  fileUploadErrorContainer: {
    backgroundColor: '#FF0033',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    fontFamily: 'Poppins',
    marginTop: '16px',
    marginBottom: '16px',
    padding: '8px 16px 8px 8px',
    display: 'flex',
    alignItems: 'center',
  },
  fileUploadErrorClose: {
    cursor: 'pointer',
  },
  rowDeleteChampionshipInfo: {
    backgroundColor: '#F1F1F3',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    '& th': {
      color: '#3C0CBF',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

const getChallengeStatusColor = (status: string) => {
  switch (status) {
    case 'DRAFT':
      return { backgroundColor: alto };
    case 'SCHEDULED':
      return { backgroundColor: serenade, color: neonCarott };
    case 'PUBLISHED':
      return { backgroundColor: grannyApple, color: funGreen };
    default:
      return { backgroundColor: grannyApple, color: funGreen };
  }
};

const BackBar = () => {
  const styles = useStyles();
  const t = useTranslate();
  const history = useHistory();

  return (
    <div className={styles.backBarWrapper} onClick={() => history.push('/Challenge')}>
      <ChevronLeftIcon />
      <span>{t('challenge.backButton')}</span>
    </div>
  );
};

const Header = ({
  challenge,
  setPublishDialogOpen,
}: {
  challenge: any;
  setPublishDialogOpen: (open: boolean) => void;
}) => {
  let manager: Manager;
  let content = localStorage.getItem('manager');
  manager = JSON.parse(content!);
  const regex = /@gymlib\.com$/;
  const isGymlibUser = regex.test(manager.email);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const styles = useStyles();
  const refresh = useRefresh();
  const t = useTranslate();

  const dataProvider = useDataProvider();

  const onNameChanged = async (newName: string) => {
    if (!!newName && newName !== challenge.name) {
      await dataProvider.update('Challenge', {
        data: { input: { id: challenge.id, name: newName } },
      });
      refresh();
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.badge} style={getChallengeStatusColor(challenge.status)}>
        {t(`challenges.header.status.${challenge.status}`)}
      </div>
      <div className={styles.titleContainer}>
        {isGymlibUser && challenge.status === 'DRAFT' ? (
          <EditableHeader
            source="name"
            maxLength={45}
            record={challenge}
            saveChanges={onNameChanged}
          ></EditableHeader>
        ) : (
          <h1 className="title">{challenge.name}</h1>
        )}
        {isGymlibUser && (
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            className="icon"
            onClick={() => setDeleteDialogOpen(true)}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {challenge.status === 'DRAFT' && isGymlibUser && (
          <MuiButton
            onClick={() => setPublishDialogOpen(true)}
            variant="contained"
            color="primary"
          >
            {t('challenges.header.publishItem')}
          </MuiButton>
        )}
      </div>
      <DeleteChallengeDialog
        challengeID={challenge.id}
        displayDialog={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
      />
    </div>
  );
};

const MIN_IMAGE_SIZE = {
  width: 600,
  height: 400,
};

const Infos = ({
  challenge,
  setPublishDialogOpen,
}: {
  challenge: any;
  setPublishDialogOpen: (ope: boolean) => void;
}) => {
  const nextMonday =
    moment().isoWeekday() > 1
      ? moment()
          .add(1, 'week')
          .isoWeekday(1)
          .startOf('day')
      : moment()
          .isoWeekday(1)
          .startOf('day');
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    challenge.publishedDate ? moment(challenge.publishedDate) : nextMonday
  );

  const styles = useStyles();
  const t = useTranslate();
  const [picture, setPicture] = useState(challenge.picture);
  const [updatedPictureUrl, setUpdatedPictureUrl] = useState();
  const dataProvider = useDataProvider();
  moment.updateLocale('fr', {
    week: {
      dow: 1,
      doy: 7,
    },
  });
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (challenge.publishedDate) setSelectedDate(moment(challenge.publishedDate));
    if (updatedPictureUrl) setPicture(updatedPictureUrl);
  }, [challenge, updatedPictureUrl]);

  const handleOpenFileSelect = () => {
    const file = document.getElementById('picture');
    file!.click();
  };

  const handleFileSelected = async (event: any) => {
    setIsLoading(true);
    const file = event.target.files[0] as File;

    try {
      const base64Body = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();

          image.onerror = error => {
            return reject(error);
          };
          image.onload = () => {
            if (
              image.width < MIN_IMAGE_SIZE.width ||
              image.height < MIN_IMAGE_SIZE.height
            ) {
              setImageError(true);
              setIsLoading(false);
              return;
            }
            return resolve(reader.result as string);
          };
          image.src = reader.result as string;
        };
        reader.onerror = e => reject(e);
        reader.readAsDataURL(file);
      });

      const updatedChallenge = await dataProvider.update('Challenge', {
        data: {
          input: {
            id: challenge.id,
            upload: {
              type: file.type,
              name: file.name,
              size: file.size,
              body: base64Body,
            },
          },
        },
      });

      challenge.picture = updatedChallenge.picture;

      setIsLoading(false);
      setUpdatedPictureUrl(updatedChallenge.data.picture);
    } catch (e) {
      setImageError(true);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.infosContainer}>
      <h2 className={styles.subtitle}>{t('challenges.infos.informations')}</h2>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div
          className={styles.datePicker}
          onClick={() => {
            if (['DRAFT', 'SCHEDULED'].includes(challenge.status))
              setPublishDialogOpen(true);
          }}
        >
          <CalendarTodayIcon />
          <DatePicker
            label={t('challenges.infos.datePickerLabel')}
            disablePast={['DRAFT', 'SCHEDULED'].includes(challenge.status)}
            shouldDisableDate={day => (day ? moment(day).isoWeekday() !== 7 : true)}
            disabled
            format="DD/MM/YY"
            value={selectedDate}
            onChange={setSelectedDate}
          />
        </div>
      </MuiPickersUtilsProvider>

      <div className={styles.fileUploadContainer}>
        <input
          type="file"
          hidden
          id="picture"
          accept=".jpg, jpeg"
          onChange={e => {
            handleFileSelected(e);
          }}
        />
        {isLoading && <Spinner />}
        {picture && <img title="couverture" src={picture} key="picture" alt="" />}
        {challenge.status === ChallengeStatus.Draft && (
          <MuiButton
            className={styles.fileUploadButton}
            variant="outlined"
            onClick={handleOpenFileSelect}
          >
            changer de couverture
          </MuiButton>
        )}
      </div>
      {imageError ? (
        <div className={styles.fileUploadErrorContainer}>
          <span>
            Votre photo n’a pas pu être chargée fichiers autorisés : Jpeg (6OOx400 px
            minimum)
          </span>
          <div>
            <Close
              className={styles.fileUploadErrorClose}
              onClick={() => setImageError(false)}
            />
          </div>
        </div>
      ) : (
        ''
      )}

      <div className={styles.descriptionContainer}>
        <p>{t('challenges.infos.description')}</p>
      </div>
    </div>
  );
};

const ChallengeDetails = () => {
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(ROWS_PER_PAGE);
  const [askPreviousPage, setAskPreviousPage] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const dataProvider = useDataProvider();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const challenge = useSelector((state: any) => state.challengeReducers.activeChallenge);
  const [needRefresh, setNeedRefresh] = useState(false);
  const isInitialLoading = useRef(true);
  const championships = useRef<any>();

  useEffect(() => {
    dataProvider
      .getOne('Challenge', {
        id,
        loadChampionships: true,
        [askPreviousPage && page ? 'last' : 'first']: perPage,
        ...(page && championships
          ? askPreviousPage
            ? { before: championships.current.pageInfo.startCursor }
            : { after: championships.current.pageInfo.endCursor }
          : {}),
      })
      .then(({ data }: { data: any }) => {
        championships.current = data.championships;
      });
  }, [dataProvider, id, page, perPage, askPreviousPage]);

  useEffect(() => {
    dataProvider
      .getOne('Challenge', {
        id,
        loadChampionships: isInitialLoading.current,
      })
      .then(({ data }: { data: any }) => {
        dispatch(updateChallengeAction(data));
        championships.current = data.championships;
        isInitialLoading.current = false;
        setNeedRefresh(false);
      });
  }, [dataProvider, dispatch, id, needRefresh]);

  if (!challenge) return null;

  return (
    <div className={styles.mainWrapper}>
      <BackBar />
      <Paper>
        <Header challenge={challenge} setPublishDialogOpen={setPublishDialogOpen} />
        <div className={styles.bodyWrapper}>
          <Infos challenge={challenge} setPublishDialogOpen={setPublishDialogOpen} />
          <Championships
            championships={championships.current}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            setAskPreviousPage={setAskPreviousPage}
            challengeStatus={challenge.status}
            challengeID={challenge.id}
            styles={styles}
            refresh={() => setNeedRefresh(true)}
          />
        </div>
        <PublishChallengeDialog
          challenge={challenge}
          displayDialog={publishDialogOpen}
          setSnackbar={setSnackbar}
          handleClose={() => setPublishDialogOpen(false)}
        />
        <Snackbar
          status={snackbar.status as SnackbarStatus}
          open={snackbar.open}
          message={snackbar.message}
          autoHideDuration={AUTOHIDE_UNDO_SNACKBAR}
          onClose={() => setSnackbar({ open: false, message: '', status: 'success' })}
        />
      </Paper>
    </div>
  );
};

export default ChallengeDetails;
