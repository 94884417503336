import React from 'react';
import { Route } from 'react-router-dom';
import ChallengeDetails from '../resources/challenges/challengeDetails';
import ChangePassword from './ChangePassword';
import SendPasswordReset from './SendPasswordReset';

export default [
  <Route exact path="/change-password/:id" component={ChangePassword} key={1} />,
  <Route
    exact
    path="/send-password-reset/:email?"
    component={SendPasswordReset}
    key={2}
  />,
  <Route exact path="/Challenge/:id" component={ChallengeDetails} key={3} />,
];
